(<template>
  <shared-pricing class="int-video-details">
    <div class="int-video-details__data-cont">
      <video-service />
    </div>
    <slot></slot>
  </shared-pricing>
</template>)

<script>
  import SharedPricing from '@/components/assignment_components/one_assignment/interactive_info/shared_components/SharedPricing';
  import VideoService from '@/components/assignment_components/one_assignment/awarded_assignment/shared_components/VideoService';

  export default {
    components: {
      'shared-pricing': SharedPricing,
      'video-service': VideoService
    }
  };
</script>

<style scoped>
  .int-video-details__data-cont {
    margin: 0 -30px;
    padding: 0 30px;
    border-top: 20px solid white;
    background: #e9ebef;
  }

  @media (max-width: 767px) {
    .int-video-details__data-cont {
      margin: 0 -15px;
      padding: 0 15px;
    }
  }
</style>
